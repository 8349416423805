import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'

import Slider from 'react-slick'
import { Navigation, Controller } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import Layout from '../components/layout'
import Explore from '../components/explore'

import ArrowDown from '../assets/images/icons/arrow-down.svg'
import PlayButton from '../assets/images/icons/play-button.svg'
import ArrowRight2Icon from '../assets/images/icons/arrow-right-2.svg'
import Shapes from '../assets/images/shapes.png'
import Circle from '../assets/images/circle.png'
import CircleGradient from '../assets/images/circle-gradient.png'
import Chevron from '../assets/images/chevron.svg'

import LarsRasmussen from '../assets/images/learn/speakers/lars.png'
import DavidKerr from '../assets/images/learn/speakers/david.png'
import JasonBarnwell from '../assets/images/learn/speakers/jason.png'
import HelgaButcher from '../assets/images/learn/speakers/helga.png'
import StephanieHamon from '../assets/images/learn/speakers/stephanie.png'
import PaulHogg from '../assets/images/learn/speakers/paul.png'
import CatherineGoodman from '../assets/images/learn/speakers/catherine.png'
import RalphBaxter from '../assets/images/learn/speakers/ralph.png'
import GaryTully from '../assets/images/learn/speakers/gary.png'
import ChrisGrant from '../assets/images/learn/speakers/chris.png'

const Speaker = ({ speaker: { name, image, description } }) => (
  <div className="max-w-[328px] md:max-w-[384px] mx-auto">
    <img src={image} />
    <div className="font-medium text-3xl mt-4 tracking-[-0.02em]">{name}</div>
    <div className="text-alt-text text-sm leading-7 mt-2">{description}</div>
  </div>
)

Speaker.propTypes = {
  speaker: PropTypes.object
}

const speakers =
  [
    {
      name: 'Lars Rasmussen',
      description: 'Lars is a world renowned computer scientist, having co-founded Google Maps and having previously served as the director of engineering for Facebook. He holds a PhD in computer science from the University of California, Berkeley.',
      image: LarsRasmussen
    },
    {
      name: 'David Kerr',
      description: 'David until recently was CEO of Bird & Bird for 26 years, and during that time built it up to be the leading global law firm advising companies being changed by tech. He has a passion for harnessing legaltech to improve the delivery of legal services, and advises or is involved with several companies and clients in the area.',
      image: DavidKerr
    },
    {
      name: 'Jason Barnwell',
      description: 'Jason is an attorney in Microsoft’s Corporate, External and Legal Affairs department. He leads the Microsoft’s Modern Legal team, with previous roles leading Legal Business, Operations and Strategy team, leading the Open Source Software practice group, and counseling the Cloud and AI platform business.',
      image: JasonBarnwell
    },
    {
      name: 'Paul Hogg',
      description: 'Paul is a Relationship Manager at Barclays. He works within a dynamic team who are tasked with driving change across the legal function and delivering at pace. He plays a pivotal role in panel firm relationships.',
      image: PaulHogg
    },
    {
      name: 'Stéphanie Hamon',
      description: 'Stéphanie ensures that the legal function of major corporations operates as an efficient business partner. She seeks to transform legal service delivery one step at a time. Expert in setting and delivering commercial management strategies for in-house legal departments as well as broader legal operations strategies and implementation.',
      image: StephanieHamon
    },
    {
      name: 'Ralph Baxter',
      description: 'Ralphs served as Chairman & CEO of Orrick for nearly a quarter of a century. He was pivotal to transforming Orrick from a domestic firm to become one of the world’s most prominent global law firms, with more than 1,100 lawyers in 25 offices across the United States, Europe and Asia.',
      image: RalphBaxter
    },
    {
      name: 'Chris Grant',
      description: 'Chris has broad experience working across the legal industry with time spent in both private practice and in-house. The majority of his career has been focused on legal operations, bringing efficiencies to traditional ways of working.',
      image: ChrisGrant
    },
    {
      name: 'Helga Butcher',
      description: 'Helga is the Interim Head of Legal Project Management and Process Improvement (EMEA) at Ashurst. She has a wealth of experience in legal project management (LPM) and has recently collaborated with industry pioneers in the creation of a LPM competency framework to help professionalise the role of an LPM.',
      image: HelgaButcher
    },
    {
      name: 'Catherine Goodman',
      description: 'Cathy leads the Practice Innovation & Knowledge Counsel at Paul Hastings, and is part of the global team named 2020 ‘Innovators of the Year’ by the International Legal Technology Association for outstanding achievements in maximising the value of innovative technology in support of legal professionals.',
      image: CatherineGoodman
    },
    {
      name: 'Gary Tully',
      description: 'Gary is the Head of Legal Operations at Gilead. He has a demonstrated history of working in the biotechnology and high tech industries. In late 2021, Gary’s team was awarded law.com’s ‘Best Legal Ops Team’ having navigated the development of Covid-19 treatment amidst the pandemic.',
      image: GaryTully
    }
  ]

const EpisodeItem = ({ episode }) => (
  <Link
    to={episode.slug}
    className="flex flex-col lg:flex-row my-10"
    key={episode.episode}
  >
    <div className="relative max-w-max">
      <img
          className="absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%] opacity-60"
          src={PlayButton}
        />
      <img
        className="h-[320px] w-[384px] object-cover"
        src={episode.thumbnail}
      />
    </div>
    <div className="flex-1 flex flex-col justify-center mt-8 lg:mt-0 lg:ml-8">
      <div className="tex-alt-text text-sm">Episode {episode.episode}</div>
      <div className="text-2.5xl mt-6 lg:leading-tight">
        {`${episode.title} ${episode.subheading ? `- ${episode.subheading}` : ''}`}
      </div>
      <div className="mt-4 text-main-black text-sm leading-7 whitespace-pre-wrap">
        {
          episode.speaker
            ? `by ${episode.speaker}`
            : ''
        }
      </div>
      <div className="mt-4 text-alt-text text-sm leading-7">{episode.subtitle}</div>
      <div className="mt-4 text-alt-text text-xs uppercase">
        {episode.duration}
      </div>
    </div>
  </Link>
)

EpisodeItem.propTypes = {
  episode: PropTypes.object
}

function LearnPage ({ data }) {
  const { allMarkdownRemark } = data
  const { nodes } = allMarkdownRemark
  const episodes = nodes.map((n) => ({
    episode: n.frontmatter.episode,
    slug: n.frontmatter.slug,
    title: n.frontmatter.title,
    subheading: n.frontmatter.subheading,
    subtitle: n.frontmatter.subtitle,
    duration: n.frontmatter.duration,
    thumbnail: n.frontmatter.thumbnail,
    speaker: n.frontmatter.speaker
  })).sort((a, b) => (a.episode > b.episode) ? 1 : -1)

  const [showMore, setShowMore] = useState(false)
  const [firstSwiper, setFirstSwiper] = useState(null)
  const [secondSwiper, setSecondSwiper] = useState(null)

  const comingSoonEpisodes = []

  for (let i = 11; i <= 11; i++) {
    comingSoonEpisodes.push(i)
  }

  const batchMainEpisodes = [
    {
      title: 'Learn from the shoulders of Giants',
      caption: 'We reveal the secrets from the battlefield in a series of masterclasses conducted by some of the most influential thinkers in the technology and legal services fields.',
      episode: episodes[0]
    },
    {
      title: 'Legal Operations in Practice',
      caption: 'Hear from our experts and clients as they share their experiences from the battlefield about innovation initiatives, the burgeoning role of legal project managers, and why legal operations is here to stay.',
      episode: episodes[4]
    }
  ]

  return (
    <Layout
      description="Learn from the shoulders of Giants. We reveal the secrets from the battlefield in a series of masterclasses conducted by some of the most influential thinkers in the technology and legal services fields."
      image="/metaimages/masterclass.png"
    >
      <div className="flex flex-col lg:flex-row align-center">
        <div className="px-6 lg:px-0 flex-1 flex">
          <div className="half-container w-full ml-auto lg:px-8">
            <div className="flex flex-col justify-center flex-1 pr-0 mt-6 lg:max-w-[528px] lg:mt-[240px]">
              <div className="text-xl font-semibold font-inter text-dark-gray">LEARN</div>
              <div>
                <Swiper
                  initialSlide={1}
                  loop
                  modules={[Controller, Navigation]}
                  onSwiper={setFirstSwiper}
                  controller={{ control: secondSwiper }}
                  navigation={
                    {
                      nextEl: '#banner-swiper-next',
                      prevEl: '#banner-swiper-prev'
                    }
                  }
                >
                  {
                    batchMainEpisodes.map((e, index) => (
                      <SwiperSlide key={index}>
                        <h1 className="text-3xl mt-6 lg:text-5xl  lg:leading-tight font-GTWalsheimPro font-medium">
                          {e.title}
                        </h1>
                        <h2 className="text-xl lg:text-2.5xl lg:leading-tight tracking-[-0.02em] font-GTWalsheimPro my-6">
                          {e.caption}
                        </h2>
                      </SwiperSlide>
                    ))
                  }
                </Swiper>
              </div>
              <div className="flex my-6">
                <button
                  className="relative bg-leg-red rounded-full h-5 w-5 flex items-center justify-center mr-2.5"
                  id="banner-swiper-prev"
                >
                  <img src={Chevron} className="h-2.5 mr-0.5" />
                </button>
                <button
                  className="relative bg-leg-red rounded-full h-5 w-5 flex items-center justify-center"
                  id="banner-swiper-next"
                >
                  <img src={Chevron} className="h-2.5 scale-x-[-1] ml-0.5" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-1 min-h-[574px] lg:mx-0 lg:min-h-[820px] lg:max-w-[50vw]">
          <Swiper
            loop
            modules={[Controller]}
            onSwiper={setSecondSwiper}
            controller={{ control: firstSwiper }}
          >
            {
              batchMainEpisodes.map(e => (
                <SwiperSlide
                  key={e.episode.episode}
                >
                  <div
                    className="h-full w-full bg-center bg-cover flex items-end"
                    style={ { backgroundImage: `url(${e.episode.thumbnail})` } }
                  >
                    <div className="flex flex-col-reverse lg:flex-row items-start lg:items-center p-6 lg:p-10 bg-[#000000] bg-opacity-60 font-GTWalsheimPro w-full lg:h-[178px]">
                        <Link
                          to={e.episode.slug}
                        >
                          <button className="flex items-center font-medium text-sm rounded-[52px] px-4 py-3 bg-leg-red text-white mt-6 lg:mt-0">
                            <img
                              className="mr-2.5"
                              width="20"
                              height="20"
                              src={PlayButton}
                            />
                            <span>Start Watching</span>
                          </button>
                        </Link>
                      <div className="text-white lg:ml-8">
                        <div className="text-sm leading-7">Episode {e.episode.episode}</div>
                        <h2 className="text-2.5xl tracking-[-0.02em] lg:max-w-[360px]">
                          {e.episode.title}
                          {
                            e.episode.subheading
                              ? ` - ${e.episode.subheading}` : ''
                          }
                        </h2>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))
            }
          </Swiper>
        </div>
      </div>
      <div className="bg-light-gray">
        <div className="default-container mx-auto font-GTWalsheimPro">
          <h3 className="text-4xl font-medium mb-8">
            Episodes
          </h3>
          {episodes.slice(0, 10).map((e) => (
            <EpisodeItem key={e.episode} episode={e} />
          ))}
          {
            showMore
              ? <>
                {
                  comingSoonEpisodes.map(episode => (
                    <div key={episode} className="flex flex-col lg:flex-row my-10">
                      <div className="relative flex flex-col items-center justify-center h-[320px] max-w-[384px] bg-black md:w-[384px]">
                        <img
                          src={CircleGradient}
                        />
                        <div className="text-4xl text-dark-gray top-[172px] absolute">Coming Soon</div>
                      </div>
                      <div className="flex-1 flex flex-col justify-center mt-8 lg:mt-0 lg:ml-8">
                        <div className="tex-alt-text text-sm">Episode {episode}</div>
                        <div className="text-2.5xl mt-6 lg:leading-tight">
                          Coming Soon
                        </div>
                      </div>
                    </div>
                  ))
                }
              </> : ''
          }
          {
            <div>
              <button onClick={() => setShowMore(!showMore)} className="flex items-center mr-6">
                <span className="mr-4 text-2.5xl tracking-[-0.02em]">Show {showMore ? 'less' : 'more'}</span>
                <img
                  className={showMore ? 'rotate-180' : ''}
                  width="16"
                  height="10"
                  src={ArrowDown}
                />
              </button>
            </div>
          }
        </div>
      </div>
      <div className="default-container mx-auto">
        <div className="pb-[68px] lg:py-[138px] md:px-[104px]">
          <div className="shadow-xs px-7 py-8 rounded bg-white relative mx-auto md:py-12 lg:py-16 md:px-12 lg:pl-20 lg:max-w-[904px]">
            <img src={Shapes} className="absolute z-[-1] w-[226px] bottom-[-68px] left-[50%] translate-x-[-50%] lg:w-[384px] lg:left-[-104px] lg:bottom-[-136px] lg:translate-y-0 lg:translate-x-0" />
            <img src={Circle} className="hidden lg:block absolute h-[164px] top-[-8px] right-[-104px]" />
            <h1 className="text-2xl lg:text-5xl lg:leading-tight font-GTWalsheimPro font-medium lg:max-w-[796px]">
              LegalEye is powering the world&apos;s best legal operations departments.
            </h1>
            <Link
              className="font-GTWalsheimPro text-lg lg:text-2.5xl text-leg-red mt-6 flex items-center"
              to="/about"
            >
              About us
              <img className="ml-1 md:ml-3" src={ArrowRight2Icon} />
            </Link>
        </div>
        </div>
      </div>
      <div className="hidden md:block">
        <div className="default-container">
          <div className="text-xl font-semibold font-inter text-dark-gray">SPEAKERS</div>
          <div className="grid grid-cols-3 gap-x-8 gap-y-20 mt-16 font-GTWalsheimPro">
            {speakers.map(speaker => (
              <Speaker key={speaker.name} speaker={speaker} />
            ))}
          </div>
        </div>
      </div>
      <div className="md:hidden py-10 px-6 lg:py-30 lg:px-0">
        <div className="container mx-auto mb-20">
          <div className="text-xl font-semibold font-inter text-dark-gray">SPEAKERS</div>
          <div className="mt-16 font-GTWalsheimPro">
            <Slider
              className="mt-16 font-GTWalsheimPro"
              {...{
                centerMode: true,
                centerPadding: 0,
                speed: 500,
                arrows: false,
                variableWidth: true
              }}
            >
              {speakers.map(speaker => (
                <div key={speaker.name} className="px-4">
                  <Speaker speaker={speaker} />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
      <Explore />
    </Layout>
  )
}

LearnPage.propTypes = {
  data: PropTypes.object
}

export const pageQuery = graphql`
  query {
    allMarkdownRemark {
      nodes {
        frontmatter {
          duration
          episode
          title
          subheading
          subtitle
          slug
          thumbnail
          speaker
        }
      }
    }
  }
`

export default LearnPage
