import React from 'react'

import BookDemoButton from './book-demo-button'

function Explore () {
  return (
    <>
      <div className="bg-main-black">
        <div className="default-container">
          <h1 className="text-3xl lg:text-6xl lg:leading-tight text-white font-GTWalsheimPro font-medium w-full lg:w-3/4">
            Explore how LegalEye can transform your legal practice.
          </h1>
          <BookDemoButton buttonClass="font-GTWalsheimPro font-medium mt-6 lg:mt-10 text-xl lg:text-2.5xl tracking-[-0.02em] rounded-full py-2 px-6 bg-leg-red text-white inline-block" />
        </div>
      </div>
    </>
  )
}

export default Explore
